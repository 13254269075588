<template lang="pug">
  v-list-item
    v-avatar.mr-3(
      :size="30"
      src="/img/departmets.png")
    v-list-item-content
      v-list-item-title {{ item.name }}
    v-list-item-actions
      v-btn(
        v-if="hasRole"
        icon
        danger
        :loading="loading"
        v-tooltip="$t('infotext.remove_department_from_role')"
        @click="toggleRoleLinking")
        i.fa.fa-trash-alt
      v-btn(
        v-else
        icon
        success
         :loading="loading"
        v-tooltip="$t('infotext.add_department_to_role')"
        @click="toggleRoleLinking")
        i.fa.fa-plus

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DepartmentItem',

  props: {
    item: Object,
    hasRole: Boolean
  },

  data: () => ({
    loading: false
  }),

  methods: {
    ...mapActions('departments', [
      'linkRole',
      'unlinkRole'
    ]),

    async toggleRoleLinking () {
      this.loading = true
      const params = {
        id: this.item.id,
        roleId: this.$route.params.role_id
      }
      if (this.hasRole) {
        let { error } = await this.unlinkRole(params)
        if (!error) {
          this.$emit('remove')
          this.$emit('change', false)
        }
      } else {
        let { error } = await this.linkRole(params)
        if (!error) {
          this.$emit('add')
          this.$emit('change', true)
        }
      }
      this.loading = false
    }
  }
}
</script>

<style>
</style>
